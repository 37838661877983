@font-face {
  font-family: 'Conv_VAGRoundedStd-Bold';
  src: url('../fonts/VAGRoundedStd-Bold.eot');
  src: local('☺'), url('../fonts/VAGRoundedStd-Bold.woff') format('woff'), url('../fonts/VAGRoundedStd-Bold.ttf') format('truetype'), url('../fonts/VAGRoundedStd-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_FuturaMedium';
  src: url('../fonts/FuturaMedium.eot');
  src: local('☺'), url('../fonts/FuturaMedium.woff') format('woff'), url('../fonts/FuturaMedium.ttf') format('truetype'), url('../fonts/FuturaMedium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Futura-Std-Bold';
  src: url('../fonts/Futura-Std-Bold.eot');
  src: local('☺'), url('../fonts/Futura-Std-Bold.woff') format('woff'), url('../fonts/Futura-Std-Bold.ttf') format('truetype'), url('../fonts/Futura-Std-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:600');

$font-family-open-sans: 'Open Sans', sans-serif;

$font-family-base: $font-family-open-sans;

$conv-bold: 'Conv_VAGRoundedStd-Bold',Sans-Serif;

$futura-medium: 'Conv_FuturaMedium',Sans-Serif;

$futura-bold: 'Conv_Futura-Std-Bold';

$icon-font-path: '../fonts/';

$grid-columns: 24;

$grid-gutter-width: 20px;

$container-large-desktop: 1200px;

$border-radius-base: 0px;

$grid-float-breakpoint:    993px;

$input-border-focus: #0066b2;

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower


.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.navbar-brand {
  float: left;
  padding: 10px 0px;
  height: auto;
}
h1,h2,h3,h4,h5,h6{  
  font-family:$conv-bold;
}

.btn-red{
  background-color:#d30000;
  border-radius:20px;
  padding: 7px 50px;
  color: #fff;
  margin-top: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: .2s ease-in-out; 
  box-shadow: -3px 4px 1px #920000;
  &:hover{
    color:#fff;
    background-color:#920000;
  }
}

.form-wrapper{
  padding:0 80px;
  margin-top:30px;
  @media(max-width:$screen-sm){
    padding:0 20px;
  }
  .form-group{
    margin-bottom:15px;
    .control-label{
      text-align:left;
      font-size: 13px;
      color:#404040;
      letter-spacing:-0.5px;
    }
    .form-control{
      border-radius:15px;
      padding: 5px 20px;
    }
    .radio-button{
      margin-top:15px;
    }
  }
  .notes{
    font-family:$futura-medium;
    color:#404040;
    font-size:14px;
    margin-top:15px;
    margin-bottom:15px;
  }
  .bottom-notes{
    font-family:$futura-medium;
    font-size:12px;
    font-style:italic;
  }
  .cta{
    text-align:center;
    margin:50px 0;
    &.right{
      text-align:right;
      @media(max-width:$screen-sm){
        text-align:center;
      }
    }
  }
}

.block-white{
  background-color:#fff;
  padding:0px 0 120px;
}

.mb-15{
  margin-bottom:20px;
}

.breadcrumb{
  background-color:transparent;
  font-family:$futura-medium;
  font-size:12px;
  color:#353535;
  padding:0;
  font-style:italic;
  margin-bottom:30px;
  a{
    color:#353535;
  }
  li + li:before {
    content: "> ";
    padding: 0 5px;
    color: #ccc;
  }
}

.navigasi{
  background-color:#eebe80;
  letter-spacing: 0.5px;
  font-family:$conv-bold;
  .navbar{
    margin-bottom:0px;
  }
  .navbar-default{
    background-color:transparent;
    border:none;
    .navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus{
      background-color:transparent;
      color:#fff;
      border-bottom:3px solid #fe0000;
      @media(max-width:$screen-sm){
        border:none;
      }
    }
    .navbar-nav > li > a{
      padding:21px 0;   
      margin:0 15px;
      transition: .2s ease-in-out;   
    }
    .navbar-nav > li > a:hover{
      opacity:0.8;     
    }
    .navbar-nav > li > a, .navbar-nav > li > a:hover, .navbar-nav > li > a:focus  {
      color: #fff;
      @media(max-width:$screen-sm){
        background-color:transparent;
      }
    }
    .navbar-nav > .open > a{
      background-color:transparent;
    }
    .navbar-nav .open .dropdown-menu > li > a{
      color:#fff;
    }
    .dropdown-menu{
      padding:0;
      background-color:transparent;
      > li > a{
        color:#fff;
        padding:15px 20px;
        transition: .2s ease-in-out;
        background-color:#fe0000;
        @media(max-width:$screen-sm){
          padding: 15px 25px;
          background-color:transparent;
        }
      }
      > li > a:hover{
        background-color:#d70000;
      }
    }
    .navbar-toggle{
      background-color:#fe0000;
      border-radius: 4px;
      margin-top: 15px;
      .icon-bar{
        background-color:#fff;
      }
    }
  }
  dl{
    padding:20px 15px;  
    margin-bottom:0;
    display:inline-block;
  }
  .dropdown dd, .dropdown dt, .dropdown ul { margin:0px; padding:0px; }
  .dropdown dd { position:relative; }
  .dropdown a, .dropdown a:visited { color:#816c5b; text-decoration:none; outline:none; }
  .dropdown a:hover { color:#5d4617;}
  .dropdown dt a:hover { color:#5d4617;}
  .dropdown dt a {background:transparent url('../images/dd-bg.png') no-repeat scroll right center; display:block; padding-right:20px;
                  }
  .dropdown dt a span {cursor:pointer; display:inline-block; color:#fff; margin-left:5px;}
  .dropdown dd ul { background:#fe0000 none repeat scroll 0 0; color:#C5C0B0; display:none;
                    right:0px; padding:5px 0px ; position:absolute; top:20px; width:auto; list-style:none; z-index:2; @media(max-width:$screen-sm){top:unset; bottom:50px;}}
  .dropdown span.value { color:#fff}
  .dropdown dd ul li a { padding:5px; display:block; width:85px;}
  .dropdown dd ul li a:hover { background-color:#d70000;}
  .dropdown dd ul li img { margin:0 10px; }
  
  .dropdown img.flag { border:none; vertical-align:middle; margin-left:10px; border: 1px solid red; border-radius: 50%; width: 22px; height: 22px; object-fit: cover;}
  .flagvisibility { display:none;}
  #result{
    display:none;
  }  
}

.weare{
  background:url('../images/bg-weare.png') no-repeat center top;
  background-size:cover;
  padding: 50px 0 60px;
  .head-title{
    font-size:55px;
  }
  p{
    font-family:$futura-medium;
    font-size: 18px;
    line-height: 35px;
    padding:50px 10% 0 0; 
    @media(max-width:$screen-sm){
      padding:0;
      text-align:center;
    }
    span{
      font-weight: bold;
      text-transform:uppercase;
    }
  }
  .img-responsive{
    margin:0 auto;
    text-align:center;
  }
}

.home-slider{
  &.slick-dotted.slick-slider {
      margin-bottom: 0px;
  }
  img{
    width:100%;
  }
  .prev,.next{        
    position: absolute;
    top: 35%;
    cursor: pointer;
    width:30px;
    height:80px;
    z-index:3;
    border-radius:50%;
    text-align:center;
    -webkit-transform:perspective(1px) translateZ(0);
            transform:perspective(1px) translateZ(0);
    box-shadow:0 0 1px transparent;
    -webkit-transition-duration:0.5s;
            transition-duration:0.5s;
    @media(max-width:$screen-sm){

      top: 55%;
      width:20px;
      height:20px;
    }
    i{
      font-size:40px;
    }
  }
  .prev:hover,.next:hover{            
    color:rgba(215,0,0, 1);
    -webkit-transform:scale(1.2);
            transform:scale(1.2);
    -webkit-transition-timing-function:cubic-bezier(0.47, 2.02, 0.31, -0.36);
            transition-timing-function:cubic-bezier(0.47, 2.02, 0.31, -0.36);
  }
  .prev{            
    left: 10%;
    @media(max-width: $screen-sm){
      left: 10px;
    }
  }
  .next{
    right: 10%;
    @media(max-width: $screen-sm){
      right: 10px;
    }
  }
  .slick-dots{
    padding-left:0;
    text-align:center;
    bottom: 35px;
    @media(max-width: $screen-md){
      bottom: 10px;
    }
    li{
      margin:0 2px;
      button:before{
        opacity: 1;
        content:url('../images/dot.png');
      }
    }
    li.slick-active{
      button:before{
        content:url('../images/dot-active.png');
      }
    }   
  }
}

.home-title{
  text-align:center;
  text-transform:uppercase;
  font-size:24px;
  margin:40px 0 30px;
}
.history-title{
  text-align:center;
  text-transform:uppercase;
  font-size:24px;
  margin:0 0 30px;
}
.history-desc{
  font-family:$futura-medium;
  font-size:16px;
  text-align:center;
  line-height: 30px;
}
.milestone{
  margin:40px 0;
  .prev,.next{        
    position: absolute;
    top: 55%;
    cursor: pointer;
    width:30px;
    height:80px;
    z-index:3;
    border-radius:50%;
    text-align:center;
    -webkit-transform:perspective(1px) translateZ(0);
            transform:perspective(1px) translateZ(0);
    box-shadow:0 0 1px transparent;
    -webkit-transition-duration:0.5s;
            transition-duration:0.5s;
    @media(max-width:$screen-sm){

      top: 55%;
      width:20px;
      height:20px;
    }
    i{
      font-size:50px;
    }
  }
  .prev:hover,.next:hover{            
    color:rgba(215,0,0, 1);
    -webkit-transform:scale(1.2);
            transform:scale(1.2);
    -webkit-transition-timing-function:cubic-bezier(0.47, 2.02, 0.31, -0.36);
            transition-timing-function:cubic-bezier(0.47, 2.02, 0.31, -0.36);
  }
  .prev{            
    left: -5%;
    @media(max-width: $screen-sm){
      left: -10px;
    }
  }
  .next{
    right: -7%;
    @media(max-width: $screen-sm){
      right: -10px;
    }
  }
}
.vision{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top:30px;
  li{
    font-size:16px;
    line-height: 30px;
    font-family:$futura-medium;

  }
}
.about-more{
  margin-top:30px;
  &.right{
    text-align:right;
  }
  p{
    font-family:$futura-medium;
    font-size:16px;
    line-height:30px;
  }
  .btn-red{
    padding:7px 30px;
  }
}

.home-yellow{
  margin-top:-5px;
  background:url('../images/bg-yellow.jpg') no-repeat center top;
  background-size:cover;
  padding-bottom:60px;
  .lefttext{
    @media(max-width: $screen-md){
      padding:0 15px;      
      text-align:center;
    }
    p{      
      font-family:$futura-medium;
      font-size:20px;
      line-height:28px;
      letter-spacing:1px;
      display:block;
      @media(max-width: $screen-md){
        font-size:14px;
      }
    }
    a{
      margin-top:30px;
    }
    .asset-1, .asset-2, .asset-3{
      position:absolute;
      @media(max-width:$screen-sm){
        display:none;
      }
    }
    .asset-1{
      left: -15%;
      top: -40%;
    }
    .asset-2{
      left: -30%;
      bottom: -35%;
    }
    .asset-3{
      right:0;
    }
  }
  .rightimg{
    min-height:100px;
    .yellow-mainimg{
      position:absolute;
      width:100%;
      margin-top:-10%;
      @media(max-width:$screen-sm){
        position:relative;
        margin-top:0;
      }
    }
    .asset-3{
      position:absolute;
      right:-12%;      
      bottom: -335%;
      @media(max-width:$screen-sm){
        display:none;
      }
    }
  }  
}

.home-white{
  background-color:#fff;
  min-height:500px;
  padding-top:30px;
  .white-wrapper{
    width:100%;
    min-height:400px;
    position:relative;
    .asset-1, .asset-2, .asset-3, .asset-4, .asset-5{
      position:absolute;
    }
    .asset-1{
      left: -10%;
      top: 0;
      @media(max-width: $screen-md){
        left: -30%;
        top: 0;
      }
    }
    .asset-2{
      left: -6%;
      bottom: -5%;
      @media(max-width: $screen-md){        
        bottom: 30%;
      }
    }
    .asset-3{
      right:15%;
      @media(max-width: $screen-md){
        right:0;
      }
    }
    .asset-4{
      left: 30%;
      bottom: -15%;
      @media(max-width: $screen-md){        
        bottom: 0;
      }
    }
    .asset-5{
      right: 0;
      top: 20%;
    }
    .product-display{
      .product-thumb{
        padding:0 20px;
        img{
          width:100%;
          margin:0 auto;
        }
      }
      .product-title{
        text-align:center;
        font-family:$conv-bold;
        text-transform:uppercase;
        font-size:20px;
        padding:15px 0;
      }
    }
    .viewmore{
      text-align:center;
      margin: 5px 0 80px;
    }
  }
}
.home-blue{
  margin-top:-5px;
  background:url('../images/bg-blue.jpg') no-repeat center top;
  background-size:cover;
  padding-bottom:60px;
  .white-wrapper{
    width:100%;
    min-height:400px;
    position:relative;
    .asset-1, .asset-2, .asset-3, .asset-4, .asset-5{
      position:absolute;
    }
    .asset-1{
      left: -10%;
      top: 0;
      @media(max-width: $screen-md){
        left: -30%;
        top: 0;
      }
    }
    .asset-2{
      left: -6%;
      bottom: -5%;
      @media(max-width: $screen-md){        
        bottom: 30%;
      }
    }
    .asset-3{
      right:15%;
      @media(max-width: $screen-md){
        right:0;
      }
    }
    .asset-4{
      left: 30%;
      bottom: -15%;
      @media(max-width: $screen-md){        
        bottom: 0;
      }
    }
    .asset-5{
      right: 0;
      top: 20%;
    }
    .featured-popular{
      background-color:#fff;
      min-height:450px;
      border-radius:10px;
      box-shadow:4px 4px 15px #d0d0d0;
      .featured-popular-img{
        .popular-image{
          width:100%;
          height:200px;
          object-fit: cover;
          border-top-right-radius:10px;      
          border-top-left-radius:10px;
        }
      }
      .featured-popular-text{
        padding:20px 30px;
        font-family:$futura-medium;
        .date{
          font-size:12px;
          color:#898989;
          span{
            background-color:transparent;
            padding:2px 15px;
            margin-left:5px;
            border-radius:20px;
            font-size:13px;
            color:#fff;
            &.news{
              background-color:#e45600;
            }
            &.article{
              background-color:#00c7e4;
            }
          }
        }
        .title-post{
          margin:10px 0;
          a{
            color:#404040;
            font-size:24px;
            font-weight: bold;
            margin:15px 0;
          }
        }
        .desc{
          color:#808080;
          a{
            color:#d50000;
            font-style:italic;
          }
        }
      }
    }
    .viewmore{
      text-align:center;
      margin: 5px 0 80px;
    }
  }
}

//CONTENT
.content{
  background:url('../images/bg-content.jpg') repeat center top;
  padding-bottom:120px;
  &.bottom-loose{
    padding-bottom:0;
  }
  .head-title{
    margin-top:50px;
    text-align:center;
    h1{
      font-size:55px;
      color:#813e08;
      text-transform:uppercase;
      @media(max-width:$screen-sm){
        font-size:30px;
      }
    }
    span{
      max-width:70%;
      margin:0 auto;
      display:block;
      font-family:$futura-medium;
      font-size: 18px;
      color: #404040;
      line-height:35px;
      @media(max-width:$screen-sm){
        font-size:15px;
        max-width:90%;
      }
    }
  }
  .title-popular{
    text-align:center;
    margin:50px 0 30px;
    h3{
      font-size:24px;
      color:#333;
      text-transform:uppercase;
    }
  }
  .featured-popular{
    background-color:#fff;
    min-height:450px;
    border-radius:10px;
    box-shadow:4px 4px 15px #d0d0d0;
    .featured-popular-img{
      .popular-image{
        width:100%;
        height:200px;
        object-fit: cover;
        border-top-right-radius:10px;      
        border-top-left-radius:10px;
      }
    }
    .featured-popular-text{
      padding:20px 30px;
      font-family:$futura-medium;
      .date{
        font-size:12px;
        color:#898989;
        span{
          background-color:transparent;
          padding:2px 15px;
          margin-left:5px;
          border-radius:20px;
          font-size:13px;
          color:#fff;
          &.news{
            background-color:#e45600;
          }
          &.article{
            background-color:#00c7e4;
          }
        }
      }
      .title-post{
        margin:10px 0;
        a{
          color:#404040;
          font-size:24px;
          font-weight: bold;
          margin:15px 0;
        }
      }
      .desc{
        color:#808080;
        a{
          color:#d50000;
          font-style:italic;
        }
      }
    }
  }
  .load-more{
    text-align:center;
    margin:50px 0;
  }
  .article-header{
    margin-bottom:30px;
    img{
      width:100%;
      height:400px;
      object-fit: cover;
    }
  }
  .article-sidebar{
    padding:30px 20px 0 20px;
    h3{
      text-transform:uppercase;
      color:#404040;
      margin-bottom:20px;
      font-size:20px;
      font-family:$conv-bold;     
    }
    .archive-wrap{
      font-family:$futura-medium;
      margin-bottom:50px;
      h5{    
        color:#404040;    
        font-size:18px;
        letter-spacing: 1px;
        margin:20px 0;
        font-family:$conv-bold; 
      }
      li{
        list-style:none;
        margin:3px 0;
        a{
          color:#404040;
        }
      }
    }
    .latestpost-wrap{
      .featured-popular{
        background-color:#fff;
        min-height:250px;
        border-radius:10px;
        box-shadow:2px 2px 15px #d0d0d0;
        .featured-popular-img{
          .popular-image{
            width:100%;
            height:180px;
            object-fit: cover;
            border-top-right-radius:10px;      
            border-top-left-radius:10px;
          }
        }
        .featured-popular-text{
          padding:20px 30px;
          font-family:$futura-medium;
          .date{
            font-size:12px;
            color:#898989;
            span{
              background-color:transparent;
              padding:2px 15px;
              margin-left:5px;
              border-radius:20px;
              font-size:12px;
              color:#fff;
              &.news{
                background-color:#e45600;
              }
              &.article{
                background-color:#00c7e4;
              }
            }
          }
          .title-post{
            margin:10px 0;
            a{
              color:#404040;
              font-size: 20px;
              line-height: 24px;
              font-weight: bold;
              margin:15px 0;
            }
          }
        }
      }
    }
  }
  .article-wrap{
    @media(max-width:$screen-sm){
      padding:0 25px;
    }
    .article-date{
      font-size:14px;
      font-family:$futura-medium;
      color:#898989;
    }
    .article-body{
      h2{
        margin:10px 0 30px;
      }
      p{
        font-family:$futura-medium;
        color:#808080;
        letter-spacing:0.7px;
        font-size:16px;
        font-weight: normal;
        margin-bottom:20px;
      }
      img{
        margin:20px auto 40px;
        text-align:center;
        max-width:100%;
        @media(max-width:$screen-sm){
          width:100%;
        }
      }
    }
    .article-share{
      margin-top:50px;
      .cap{
        padding:5px 0 0;
        font-family:$futura-medium;
        letter-spacing:0.7px;
        font-size:14px;
        color:#b1b1b1;
        @media(max-width:$screen-sm){
          margin-bottom:15px;
        }
      }
      .list-icon{
        ul{
          padding:0;
        }
        li{
          display:inline-block;
          margin:0 3px;
          background-color:#000;
          border-radius:3px;
          width: 90px;
          text-align: center;
          transition:.2s ease-in-out;
          @media(max-width:$screen-sm){
            width: 47%;
            margin-bottom:10px;
          }
          &.fb{
            background-color:#3b5998;
          }
          &.tw{
            background-color:#0084b4;
          }
          &.gp{
            background-color:#d34836;
          }
          &.in{
            background-color:#0e76a8;
          }
          &.pin{
            background-color:#C92228;
          }
          &:hover{
            opacity:0.8;
            transition:.2s ease-in-out;
          }
          a{
            display:block;
            padding:5px 0px;
          }
          i{
            color:#fff;
          }
        }
        
      }
    }
  }
  .news-header{
    margin-top: 35px;
    .box-wrapper{
      background-color:#fff;
      border-radius:10px;
      box-shadow:4px 4px 15px #d0d0d0;
      .featured-img{
        img{
          width:100%;
          min-height:420px;
          object-fit: cover;
          border-bottom-right-radius:10px;
          border-top-right-radius:10px;
          transition:.2s ease-in-out;
          @media(max-width:$screen-sm){
            border-radius:0px !important;
             min-height:200px;
          }
          &:hover{
            opacity:0.8;
            transition:.2s ease-in-out;
          }
        }
      }
      .featured-text{
        font-family:$futura-medium;
        padding:0px 50px;
        height:420px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        .date{
          font-size:13px;
          color:#848484;
        }
        h2{          
          font-family:$futura-medium;
          font-weight: bold;
          text-transform:none;
          font-size: 36px;
          line-height: 45px;
          letter-spacing: 1.5px;
          a{
            color: #404040;
          }
        }
        p{
          color:#848484;
          font-size: 15px;
          line-height: 25px;
          a{
            color:#d50000;
            font-style:italic;
          }
        }
      }
    } 
  }
  .businesslink-box{
    margin-top: 35px;
    .box-wrapper{
      background-color:#fff;
      border-radius:10px;
      box-shadow:4px 4px 15px #d0d0d0;
      .featured-img{
        .left, .right{
          @media(max-width:$screen-sm){
            border-radius:0px !important;
          }
        }
        .left{
          border-bottom-left-radius:10px;
          border-top-left-radius:10px;
        }
        .right{
          border-bottom-right-radius:10px;
          border-top-right-radius:10px;
        }
      }
      .featured-text{
        padding:20px 50px;
        h2{
          text-transform:uppercase;
          font-size: 34px;
          line-height: 50px;
          color: #404040;
        }
        p{
          font-family:$futura-medium;
          color:#848484;
          font-size: 15px;
          line-height: 25px;
        }
      }
    }    
  } 
  .contact-page{
    padding-left:25px;
    @media(max-width:$screen-sm){
      padding:0 10px;
    }
    h3{
      font-size:28px;
      color:#404040;
      margin-top:0;
      text-transform:uppercase;
    }
    p{
      font-family:$futura-medium;
      font-size:16px;
      color:#404040;
      margin-bottom:30px;
      span{
        display:block;
        font-size:18px;
      }
    }
  } 
  .aboutus-page{
    margin-top:50px;
    .advantage-slider, .service-slider{
      .slider-item{
        padding:10px;
        margin-top:35px;
        @media(max-width:$screen-sm){
          margin-top:50px;
        }
        .slider-bg{
          padding:15px;
          background-color:#fff;
          height:300px;
          border-radius:10px;
          text-align:center;
          box-shadow:4px 4px 15px #d0d0d0;
          .icon{
            display:block;
            text-align:center;
            margin:-23% auto 0 auto;
          }
          h2{
            font-size: 18px;
            color: #404040;
            text-transform: uppercase;
          }
          p{
            font-family:$futura-medium;
            font-size:13px;
            color:#848484;
            text-overflow:ellipsis;
          }
          a{
            display:block;
            color:#d50000;
          }
        }
      }
      .prev,.next{        
        position: absolute;
        top: 40%;
        cursor: pointer;
        width:30px;
        height:80px;
        z-index:3;
        border-radius:50%;
        text-align:center;
        -webkit-transform:perspective(1px) translateZ(0);
                transform:perspective(1px) translateZ(0);
        box-shadow:0 0 1px transparent;
        -webkit-transition-duration:0.5s;
                transition-duration:0.5s;
        @media(max-width:$screen-sm){

          top: 55%;
          width:20px;
          height:20px;
        }
        i{
          font-size:40px;
        }
      }
      .prev:hover,.next:hover{            
        color:rgba(215,0,0, 1);
        -webkit-transform:scale(1.2);
                transform:scale(1.2);
        -webkit-transition-timing-function:cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function:cubic-bezier(0.47, 2.02, 0.31, -0.36);
      }
      .prev{            
        left: -20%;
        @media(max-width: $screen-sm){
          left: -10px;
        }
      }
      .next{
        right: -20%;
        @media(max-width: $screen-sm){
          right: -10px;
        }
      }
      .slick-dots{
        padding-left:0;
        text-align:center;
        bottom: -75px;
        @media(max-width: $screen-md){
          bottom: -50px;
        }
        li{
          margin:0 2px;
          button:before{
            opacity: 1;
            content:url('../images/dot.png');
          }
        }
        li.slick-active{
          button:before{
            content:url('../images/dot-active.png');
          }
        }   
      }
    }
  }
}

//FOOTER
.footer{
  background:url('../images/bg-footer.jpg') no-repeat bottom center;
  background-size:cover;
  min-height:180px;
  padding-top:100px;
  @media(max-width:$screen-sm){
    min-height:80px;
    padding-top:30px;
  }
  .copyright, .social-icon {
    @media(max-width:$screen-sm){
      text-align:center !important;
    }
  }
  .copyright{
    color:#fff;
    font-size:12px;
    font-weight: bold;
  }
  .social-icon{
    text-align:right;
    ul.social-media{
      @media(max-width:$screen-sm){
        padding:0;
        margin:15px 0;
      }
      li{
        display:inline-block;
        width:25px;
        height:25px;
        border-radius: 50%;
        background-color:#fff;
        text-align:center;
        margin: 0 10px;        
        transition: .2s ease-in-out;
        &:hover{
          background-color:#fe0000;
          a i{
            color:#fff;
          }
        }
        a{
          display:block;
          i{
            color:#a49681;
            margin-top:5px;
          }
        }        
      }
    }
  }
}


/* ---- button-group ---- */

.button-group{
  margin-bottom:60px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  .button {
    background-color:#d30000;
    border-radius:20px;
    padding: 7px 50px;
    color: #fff;
    margin-top: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    transition: .2s ease-in-out; 
    box-shadow: -3px 4px 1px #920000;
    text-transform:uppercase;
    border:none;
    outline:none;
    @media(max-width:$screen-sm){

    padding: 7px 10px;
    font-size:12px;
    }
    &:hover{
      color:#fff;
      background-color:#920000;
    }
    &:active{
      background-color: #920000;
    }
    &.is-checked {
      background-color: #920000;
      color: white;
    }
  }
  .img-responsive{
    margin:0 auto;
  }
}


/* ---- isotope ---- */

.grid {
  &:after{
    content: '';
    display: block;
    clear: both;
  }
  .element-item {
    position: relative;
    float: left;
    width: 20%;
    min-height: 200px;
    padding: 10px;
    text-align:center;
    margin-bottom:15px;
    @media(max-width:$screen-sm){
      width:50%;     
    min-height: 200px;
    }
    .img-responsive{
      margin: 0 auto;
      outline:none;
    }
    .product-name {
      font-family:$futura-bold;
      color:#4d4d4d;
      font-size:17px;
      line-height:22px;
      margin:20px 0 7px;
      padding: 0 20px;
    }
    .flavor {
      font-family:$futura-medium;
      font-size:14px;
      color:#808080;
    }
    .number {
      position: absolute;
      right: 8px;
      top: 5px;
    }
    .weight {
      position: absolute;
      left: 10px;
      top: 76px;
      font-size: 12px;
    }
  }

  .element-item > * {
    margin: 0;
    padding: 0;
  }
  
}
.modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width:800px;
}
.modal-content{
  .close{
    width: 40px;
    height: 40px;
    background-color: #d30000;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    opacity: 1;
    position: absolute;
    top: -6%;
    right:-2%;    
    box-shadow: -3px 4px 1px #920000;
    z-index:2;
  }
}
.modal-body{
  .img-responsive{
    width:100%;
  }
  .product-title{
    font-family:$futura-bold;
    color:#4d4d4d;
    font-size:20px;
    line-height:28px;
    margin:30px 0 15px;
  }
  .product-desc{
    font-family:$futura-medium;
    font-size:14px;
    color:#808080;
    letter-spacing: 0.5px;
  }
  table{
    font-family:$futura-medium;    
    color:#808080;    
    letter-spacing: 0.5px;
    .bold{
      color:#000;
      font-weight:bold;
    }
  }
}


